const colors = {
  lpHeaderScrollColor: '#F0F0F0',
  lpBodyColorGradient:
    'linear-gradient(180deg, #FFFFFF 0%, #D9D9D9 13.54%, #FFFFFF 55.73%);',
  lpBannerTitleBoldColor: '#F6506B',
  lpFooterColor: '#10b0a1',
  lpBannerTitleColor: '#1D1D1D',
  lpBannerPrizeColor: '#FFFFFF',
  lbBannerWinnerColr: '#D9D9D9',
};

const fonts = {
  headerLinks: {
    fontFamily: 'mainfont',
    fontWeight: 400,
    color: 'black',
  },
};

const components = {
  Link: {
    variants: {
      //LandingPage Header Links
      lpHeaderLinks: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        display: { base: 'none', md: 'flex' },
        _hover: { textDecor: 'underline' },
        color: 'black',
      },
      lpFooterLinks: {
        fontSize: ['20px', '30px'],
        fontWeight: 700,
        color: 'white',
        fontFamily: 'Montserrat',
        _hover: { textDecor: 'underline' },
      },
      loginVoltar: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: ['14px', '15px', '16px', '17px', '18px'],
        color: '#000000',
      },
    },
  },
  Heading: {
    variants: {
      lpBannerTitle: {
        color: '#1D1D1D',
        letterSpacing: '1px',
        fontWeight: '900',
        fontSize: ['29px', '18px', '30px', '38px', '45px', '48px'],
        textAlign: 'center',
        fontFamily: 'mainfont',
      },
      lpDescriptionInfos: {
        opacity: '80%',
        textAlign: ['justify', 'center'],
        fontSize: ['17px', '14px', '14px', '18px', '26px'],
        color: '#FFFFFF',
        fontFamily: 'Raleway',
        fontWeight: 400,
        lineHeight: '140%',
      },
    },
  },
  Text: {
    variants: {
      lpDescriptionInfos: {
        opacity: '80%',
        textAlign: ['justify', 'center'],
        fontSize: ['17px', '14px', '14px', '18px', '26px'],
        color: '#FFFFFF',
        fontFamily: 'Raleway',
        fontWeight: 400,
        lineHeight: '140%',
      },
      lpDescriptionInfos2: {
        opacity: '80%',
        textAlign: ['justify', 'center'],
        fontSize: ['17px', '14px', '14px', '18px', '25px'],
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        lineHeight: '140%',
      },
      // lpBannerTitle: {
      //   fontFamily: 'mainfont',
      //   textAlign: 'center',
      //   fontSize: ['29px', '20px', '20px', '28px', '45px'],
      //   color: 'lpBannerTitleColor',
      //   fontWeight: 700,
      //   lineHeight: '100%',
      // },
      lpDescriptionInfosLoginTitle: {
        fontSize: ['14px', '17px', '18px', '20px', '25px'],
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 400,
      },
      lpDescriptionInfosLogin: {
        fontSize: ['14px', '17px', '18px', '19px', '20px'],
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 700,
      },
      lpDescriptionInfosValidateinfos: {
        fontSize: ['14px', '17px', '18px', '19px', '20px'],
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 400,
      },
      lpDescriptionInfosValidateinfosTitle: {
        fontSize: ['14px', '17px', '18px', '20px', '25px'],
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 700,
      },
      lpBannerTitle2: {
        color: '#F6506B',
      },
      lpBannerTitle3: {
        fontWeight: 400,
        textAlign: ['justify', 'center'],
        color: '#1D1D1D',
        lineHeight: '120%',
        fontFamily: 'Raleway',
        fontSize: ['15px', '17px', '19px', '22px'],
        letterSpacing: '0.03em',
      },
      lpFooterText: {
        fontWeight: 400,
        fontFamily: 'Inter',
        color: 'white',
        fontSize: ['8px', '8px', '8px', '10px', '12px'],
        textAlign: ['justify', 'center'],
      },
      lpFooterText2: {
        fontWeight: 700,
        fontFamily: 'Inter',
        color: 'white',
        fontSize: ['8px', '8px', '8px', '10px', '12px'],
        textAlign: ['justify', 'center'],
      },
      checkboxLogin: {
        fontFamily: 'Montserrat',
        fontSize: ['xx-small', '13px'],
        fontWeight: '400',
        color: '#FFFFFF',
      },
    },
  },
  Button: {
    variants: {
      lpHeaderButton: {
        borderRadius: 60,
        pl: 10,
        pr: 10,
        bgColor: '#FF0000',
        _hover: { bg: '#D9D9D9' },
        _active: { bg: '#D9D9D9' },
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: 700,
        color: 'white',
      },
      lpBannerButton: {
        fontWeight: 700,
        fontFamily: 'mainfont',
        lineHeight: '30.48px',
        fontSize: ['17px', '13px', '17px', '21px', '26px'],
        bgColor: '#7C7C7C',
        _hover: { bg: '#D9D9D9' },
        _active: { bg: '#D9D9D9' },
        pl: ['8', '9'],
        pt: ['4', '5'],
        pb: ['4', '5'],
        pr: ['8', '43'],
        borderRadius: '43.5px',
      },
      lpLoginButton: {
        fontFamily: 'mainfont',
        borderWidth: 'hex',
        borderColor: '#1D1D1D',
        border: '1px solid black',
        _hover: { bg: '#D9D9D9' },
        _active: { bg: '#D9D9D9' },
        outline: '2px solid transparent',
        outlineOffset: '2px',
        color: '#1D1D1D',
      },
      lpLoginCpfButton: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: ['14px', '15px', '16px', '17px', '18px'],
        color: '#FFFFFF',
      },
    },
  },
};

export { colors, fonts, components };
