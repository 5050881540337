import { extendTheme } from '@chakra-ui/react';
import defaultColors  from '@enums/colors';

import { colors, fonts, components } from './theme-rede';


const theme = extendTheme({
  colors: {
    ...defaultColors,
    ...colors,
  },
  components: {
    Link: {
      variants: {
        ...components.Link.variants,
      },
    },
    Heading: {
      variants: {
        ...components.Heading.variants,
      },
    },
    Text: {
      variants: {
        ...components.Text.variants,
      },
    },
    Button: {
      variants: {
        ...components.Button.variants,
      },
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
    ...fonts,
    mainfont: 'Raleway',
  },
  styles: {
    global: () => ({
      body: {
        overflow: 'auto',
        bg: 'rgba(255,255,255,1)',
        color: '#000',
      },
      b:{
        color: 'lbHeaderTextColor'
      }
    }),
  },
});

export { theme };
